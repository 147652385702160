.contrast-changer {
  display: flex;
  margin-right: 8px;

  .changer-btn {
    align-self: center;
    border: 2px solid #585858;
    width: 60px;
    height: 50px;
    margin-left: -1px;
    margin-right: -1px;
    @media all and (max-width: 1260px) {
      width: 54px;
      height: 44px;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
    user-select: none;
    z-index: 0;
    img {
      padding: 6px;
      user-select: none;
      width: 100%;
      height: auto;
      opacity: 0.65;
    }

    &:active {
      border: 2px solid #1256bc;
    }

    &:hover {
      z-index: 1;

      img {
        opacity: 1;
      }
    }
  }
}
