.stradomia-wierzchnia-page {
  .pageWrapper__section {
    table {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  .map-section {
    padding-top: 32px;
  }
}