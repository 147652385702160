.shortInfoLink {
  margin-top: 24px;
  margin-bottom: 24px;
  border: 2px solid #1256BC;
  box-sizing: border-box;

  @media all and (max-width: 600px) {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.03);
    outline: 1px solid #1256BC;
  }

  .topLinkWrapper {
    display: flex;
    align-items: center;

    .img_wrapper {
      min-width: 90px;
      width: 90px;
      height: 70px;
      text-align: center;
      margin-right: 24px;

      @media all and (max-width: 400px){
        min-width: 50px;
        width: auto;
      } 

      img {
        padding: 12px;
        justify-self: center;
        height: 100%;
      }
    }

    .content__wrapper {
      height: 100%;

      .title {
        color: #1256BC;
        font-weight: 500;
        font-size: 2.0rem;
        letter-spacing: 0.05em;
        transition-duration: 0.2s;
      }

      .description {
        @media all and (max-width: 1600px) {
          padding-top: 4px;
        }

        padding-top: 8px;
        font-size: 1.6rem;
        color: black;
        font-weight: 300;
        letter-spacing: 0.05em;
      }
    }

    &:hover {
      cursor: pointer;

      .content__wrapper {
        .title {
          text-decoration-line: underline;
          text-underline-offset: 3px;
          color: #569AFF;
        }
      }
    }
  }
}