html {
  /* font-size: 10px; */
  font-family: "Roboto", sans-serif;
  color: black;
  overflow: auto;
  background: white;
}
.font-size-6 {
  font-size: 6px;
}
.font-size-7 {
  font-size: 7px;
}
.font-size-8 {
  font-size: 8px;
}
.font-size-9 {
  font-size: 9px;
}
.font-size-10 {
  font-size: 10px;
}
.font-size-11 {
  font-size: 11px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}

@media all and (max-width: 1200px) {
  .font-size-6 {
    font-size: 5px;
  }
  .font-size-7 {
    font-size: 6px;
  }
  .font-size-8 {
    font-size: 7px;
  }
  .font-size-9 {
    font-size: 8px;
  }
  .font-size-10 {
    font-size: 9px;
  }
  .font-size-11 {
    font-size: 10px;
  }
  .font-size-12 {
    font-size: 11px;
  }
  .font-size-13 {
    font-size: 12px;
  }
  .font-size-14 {
    font-size: 13px;
  }
}

@media all and (max-width: 600px) {
  .font-size-6 {
    font-size: 4px;
  }
  .font-size-7 {
    font-size: 5px;
  }
  .font-size-8 {
    font-size: 6px;
  }
  .font-size-9 {
    font-size: 7px;
  }
  .font-size-10 {
    font-size: 8px;
  }
  .font-size-11 {
    font-size: 9px;
  }
  .font-size-12 {
    font-size: 10px;
  }
  .font-size-13 {
    font-size: 11px;
  }
  .font-size-14 {
    font-size: 12px;
  }
}

.theme-dark {
  filter: invert(1) contrast(1.2);
}

.theme-dark * {
  font-weight: 700 !important;
  /* letter-spacing: 0px !important; */
}

.theme-dark .no-invert {
  filter: invert(1);
}

.theme-dark .no-invert-white {
  filter: invert(1) brightness(0);
}

.theme-dark .menuBtn img {
  filter: brightness(0);
}

.theme-dark .changer-btn,
.theme-dark .pjm-logo,
.theme-dark .inputWrapper,
.theme-dark input,
.theme-dark .checkbox__box,
.theme-dark textarea {
  border-color: black !important;
  opacity: 1 !important;
}
.theme-dark textarea::placeholder,
.theme-dark input::placeholder {
  color: black !important;
}

body {
  position: relative;
  overflow: hidden;
}

*,
:before,
:after {
  box-sizing: border-box;
  margin: 0px;
}
