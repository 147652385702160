.contact-page {
  .standard-text-block{
    a {
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 1px;
      font-size: 1.5rem;
      display: block;
    }

    .smallBr{
      display: block;
      height: 8px;
    }
  }
}