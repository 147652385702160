.prescriptions-page {
  .pageWrapper__section {
    .loginInfoWrapper {
      padding-left: 196px;
      display: flex;
      flex-direction: column;

      @media all and (max-width: 1600px) {
        padding-left: 128px;
      }

      @media all and (max-width: 1200px) {
        padding-left: 0px;
        margin-top: 128px;
      }

      @media all and (max-width: 600px) {
        margin-top: 48px;
      }

      .grayBox {
        padding: 128px;
        color: #e62424;
        font-size: 3.6rem;
        text-align: center;
        letter-spacing: 0.05em;
        margin: auto;
        background: #e5e5e5;
        margin-bottom: 32px;

        @media all and (max-width: 1800px) {
          padding: 96px;
          width: 100%;
          font-size: 3.2rem;
        }

        @media all and (max-width: 1600px) {
          padding: 48px;
          font-size: 2.8rem;
        }

        @media all and (max-width: 1200px) {
          padding: 32px;
          font-size: 2.6rem;
          max-width: 400px;
        }

        @media all and (max-width: 600px) {
          padding: 16px;
          font-size: 2rem;
          letter-spacing: 0px;
          max-width: 260px;
        }
      }

      .loginLogo {
        width: 100%;
        max-width: 260px;
        margin: 32px auto;
        height: auto;
        // cursor: pointer;

        @media all and (max-width: 600px) {
          margin-top: 0px;
          max-width: 200px;
        }
      }
    }

    hr {
      margin-bottom: 64px;
    }
  }
}

.downloadBlocksWrapper {
  display: flex;
  flex-direction: row;

  @media all and (max-width: 700px) {
    flex-wrap: wrap;
    width: 100%;
  }
}

.prescriptions-form {
  .form-child.CheckBoxInput,
  .form-child.TextInput {
    width: 100%;
  }
  .form-child.type_textarea {
    width: 100%;
  }

  .btnWrapper {
    display: flex;
  }

  .validator,
  .callback {
    transform: scaleY(0);
    color: #e62424;
    transition-duration: 0.2s;
    font-weight: 500;
    opacity: 0;
    height: 0px;

    &.isActive {
      height: 20px;
      transform: scaleY(1);
      opacity: 1;
    }
  }

  .callback {
    color: #2ab331;
  }

  form {
    display: flex;
    flex-wrap: wrap;

    .inputBlock {
      width: calc(100% - 32px);
      flex-direction: column;
      margin: 6px 16px;
      display: flex;

      @media all and (max-width: 1200px) {
        margin: 0px 16px 8px 16px;
      }

      &.halfWidth {
        width: calc(50% - 32px);
        margin: 16px;

        @media all and (max-width: 1200px) {
          width: 100%;
          margin: 0px 16px 8px 16px;
        }
      }

      label {
        width: 100%;
        font-size: 1.2rem;
        font-weight: 400;
        padding-bottom: 4px;
        padding-left: 8px;
        // transform: scaleY(0);
        // transition-duration: 0.3s;
        text-transform: uppercase;
        letter-spacing: 0.05rem;

        // &.isActive {
        //   transform: scaleY(1);
        // }
      }

      input,
      textarea {
        width: 100%;
        padding: 12px;
        border: 2px solid #585858;
        letter-spacing: 0.05rem;
        font-size: 1.8rem;

        &::placeholder {
          font-size: 1.6rem;
          color: #585858;
          opacity: 1;
        }

        &:active {
          border-color: #1256bc;
        }

        &:focus {
          border-color: #1256bc;
        }
      }
      input.red,
      textarea.red {
        border: 2px solid red;
      }

      textarea {
        font-family: "Roboto" !important;
        resize: vertical;
      }

      &.inputBlock--checkbox {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-top: 16px;
        cursor: pointer;
        user-select: none;

        &.isDisabled {
          cursor: not-allowed;
          opacity: 0.4;
        }

        .checkbox__box {
          width: auto;
          min-height: 20px;
          border: 1px solid #929292;
          min-width: 20px;
          max-height: 20px;
          max-width: 20px;
          margin-right: 16px;

          &.checkbox__box--active {
            box-shadow: inset 0px 0px 0px 3px white;
            background: #1256bc;
          }
        }

        .checkbox__label {
          transform: scaleY(1) translateY(-3px);
          width: auto !important;
          font-weight: 300;
          font-size: 1.6rem;
          letter-spacing: 0.05em;
          text-transform: none;
        }
      }
    }
  }
}

.prescriptions-info {
  color: red;
}

.bg-light{
  padding: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
  border: 2px solid black;
}
.prescriptions-row {
  margin-top: 8px;
  margin-bottom: 8px;
  background: hsl(216, 83%, 95%);
  padding: 8px 8px 16px 8px;

  .show-on-mobile{
    display: none;
    @media all and (max-width: 900px) {
      display: initial;
    }
  }
  .btn-material-cancel{
    margin-top: -6px;
    @media all and (max-width: 1200px) {
      margin-top: 12px;
    }
  }
  .text-center{
    text-align: center;
  }
  .TextInput {
    padding-top: 2px;
    padding-bottom: 2px;
    input {
      // border-left-width: 0px !important;
    }
  }
  .first-child {
    .TextInput {
      input {
        // border-left-width: 2px !important;
      }
    }
  }
}
