.CheckBoxInput {
  position: relative;
  padding: 8px 0px;
  width: 100%;

  .validation-messages {
    transition-duration: 0.15s;
    display: inline-block;
    transform: translateY(20px);
    color: var(--color-error);
    opacity: 0;

    &.active {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .label {
    display: block;
    padding-bottom: 5px;
    padding-top: 2px;
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: -0.03em;
  }

  .labelFor {
    width: 100%;
    padding: 10px 12px 9px 12px;
    padding-left: calc((2 * 12px) + 8px);
    cursor: pointer;
    user-select: none;
    letter-spacing: 0px;
  }

  input {
    left: 12px;
    position: absolute;
    top: calc(50% + 1px);
    transform: translateY(-50%) scale(1.15);
    accent-color: var(--color-fonts);
    
    &:focus {
      &::after {
        content: "";
        position: absolute;
        // box-shadow: 0px 0px 0px 2px #1256bc;
        border: 2px solid #1256bc;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  .checkboxRow {
    position: relative;
    width: 100%;
    border-width: 2px;
    font-size: 1.4rem;
    letter-spacing: -0.03em;
    border-color: var(--color-border-light);
    border-style: solid;
    background: var(--color-background);
    color: var(--color-fonts);
    display: flex;
  }

  &.CheckBoxInput--disabled {
    cursor: initial !important;

    .checkboxRow {
      background: var(--color-bg-panel-60a);
      color: var(--color-fonts-inactive);
    }
    label {
      cursor: initial;
    }
  }
}
