.collapsible-block {
  background: white;
  width: 100%;
  // margin-top: 16px;
  // margin-bottom: 16px;
  border-top: 1px solid black;

  .collapsible-block__title {
    text-align: center;
    padding: 32px;
    user-select: none;
    cursor: pointer;
    position: relative;
    display: flex;

    @media all and (max-width: 1600px) {
      padding: 32px;
      font-size: 3.2rem;
    }

    @media all and (max-width: 1200px) {
      padding: 24px;
      font-size: 2.8rem;
    }

    @media all and (max-width: 800px) {
      padding: 16px 64px 16px 16px;
      text-align: left;
      font-size: 2.4rem;
    }

    @media all and (max-width: 500px) {
      font-size: 2rem;
    }

    .title {
      font-weight: 500;
      font-size: 2rem;
      text-align: left;
      letter-spacing: 0.05em;
      color: #1256BC;

      @media all and (max-width: 800px) {
        font-size: 1.8rem;
      }

    }

    &:hover {
      .title {
        text-decoration: underline;
        text-underline-offset: 3px;
        text-shadow: 0px 0px 1px #00000080;
      }
    }

    .imgIcon {
      margin-right: 32px;
      width: 16px;
      transform: translateY(-2px);
      transition-duration: 0.3s;

      @media all and (max-width: 800px) {
        margin-right: 16px;
        width: 12px;
        transform: translateY(0px);
      }

    }

    .imgIcon.isOpen {
      transform: translateY(2px) rotate(-180deg);
      @media all and (max-width: 800px) {
        transform: translateY(0px) rotate(-180deg);
      }
    }

    &.isSplited {
      padding-right: calc(60% + 32px);

      @media all and (max-width: 1600px) {
        padding-right: 50%;
      }

      @media all and (max-width: 900px) {
        padding-right: 16px;
      }
    }
  }

  .collapsible-block__content {
    padding: 32px;
    padding-top: 0px;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.6;
    margin-left: calc(16px + 32px); // icon + title margin

    @media all and (max-width: 1600px) {
      padding: 32px;
    }

    @media all and (max-width: 1200px) {
      padding: 24px;
      line-height: 1.2;
    }

    @media all and (max-width: 900px) {
      margin-left: 0px;
    }

    @media all and (max-width: 800px) {
      padding: 16px;
      line-height: 1.2;
      padding-top: 0px;
    }


    &.isSplited {
      margin-left: calc(40% + 32px);
      width: calc(60% - 32px);

      @media all and (max-width: 1600px) {
        margin-left: calc(50% + 16px);
        width: calc(50% - 16px);
      }

      @media all and (max-width: 900px) {
        width: 100%;
        margin-left: 0px;
      }
    }


  }



  .ReactCollapse--collapse {
    transition: height 0.3s;
  }
}

.collapsible-blocks-wrapper {
  border-bottom: 1px solid black;
}