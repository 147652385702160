.row-info{
    display: flex;
    flex-direction: column;
    padding: 15px 5px;
    border-bottom: 2px solid #569AFF;

    .row-info__name{
        font-weight: 500;
        font-size: 2.0rem;
        letter-spacing: 0.05em;
        padding-bottom: 16px;
        padding-top: 16px;
    }


    .row-info__description{
        display: grid;
        grid-template-columns: 1fr 20%;
        grid-auto-flow: row;
        padding-top: 16px;
        font-size: 1.6rem;
        color: black;
        font-weight: 300;
        letter-spacing: 0.05em;
        line-height: 1.6;
        column-gap: 15px;

        @media all and (max-width: 500px){
            grid-template-columns: 1fr 40%;
        }

        .left{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-flow: row;
            row-gap: 10px;
            column-gap: 15px;

            @media all and (max-width: 500px){
                display: grid;
                grid-template-columns:1fr;
                grid-auto-flow: row;
            }

            .left_detail.separate{
                border-right: 2px solid #569AFF;

                @media all and (max-width: 500px){
                    border-right-width:0px ;
                }
    
            } 
        }
    
    }
}
.row-info.pharmacy{
    .row-info__description{
        @media all and (max-width: 500px){
            grid-template-columns: 1fr;
        }
        .left{ 
            grid-template-columns: 1fr 1fr;
        }    
    }
}
.row-info.offices .left span{
    display: block;
}

.row-info.prices .row-info__description{
        display: block;
        
        p{
            display: flex;
            margin-bottom: 10px;
            align-items: center;

            span{
                flex-basis: 70%;

                &:last-child{
                    text-align: right;
                    flex-basis: 30%;
                }
            }
        }
}


.row-info.treatment  {
    border-bottom: none;
    .row-info__description{
        display: block;
    }
    
}