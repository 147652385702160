.navbarBarWrapper {
  position: fixed;
  width: 100%;
  z-index: 9999;
  background: #f6cf05;


  .yellowInfoBar {
    height: 50px;
    display: flex;
    align-items: center;
    background: #f6cf05;
    top: 0px;
    transition-duration: 0.2s;

    @media all and (max-width: 600px) {
      height: 70px;
    }

    .title {
      margin-left: 128px;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 0.1em;
      margin-right: auto;
      padding-left: 8px;

      @media all and (max-width: 1200px) {
        margin-left: 32px;
        padding-left: 0px;
        letter-spacing: 0px;
        font-size: 1.4rem;
        padding-right: 16px;
      }
    }

    .topLinkWrapper {
      margin-right: 128px;
      letter-spacing: 0.05em;
      text-decoration-line: underline;
      font-size: 1.6rem;
      cursor: pointer;

      @media all and (max-width: 1200px) {
        margin-right: 32px;
        letter-spacing: 0px;
        font-size: 1.4rem;
      }
    }

    &.isHidden {
      transform: scaleY(0);
      opacity: 0;
      height: 0px;
    }
  }
}

hr {
  margin-top: 32px;
  margin-bottom: 32px;
  border-width: 0px;
  border-bottom: 1px solid #656565;

  @media all and (max-width: 1200px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.navbarBar {
  width: 100%;
  height: 100px;
  background: white;
  z-index: 9988;
  border-bottom: 1px solid #f2f2f2;
  display: flex;

  @media all and (max-width: 1200px) {
    height: 80px;
  }

  @media all and (max-width: 1000px) {
    height: auto;
    flex-wrap: wrap;
    align-items: center;
  }

  .nav-tools {
    display: flex;
    align-items: center;
    
    @media all and (max-width: 1000px) {
      width: 100%;
      justify-content: right;
      padding-bottom: 16px;
    }
    @media all and (max-width: 600px) {
      padding-bottom: 8px;
      padding-top: 8px;
      flex-wrap: wrap;
    }

    @media all and (max-width: 300px) {
      flex-wrap: nowrap;
      justify-content: center;

      .pjm-logo{
        margin-right: 0;
      }
    }
  }
}

.navbarLogo {
  margin-left: 28px;
  margin-right: auto;
  height: 100px;
  align-items: center;
  z-index: 999;
  display: flex;
  z-index: 999;

  img {
    cursor: pointer;
    height: 46px;
  }

  @media all and (max-width: 1200px) {
    top: 32px;
    left: 32px;
    height: 80px;

    img {
      height: 38px;
    }
  }

  @media all and (max-width: 300px) {
    margin-left: 5px;
    top: 0; 
  }

  
}

.menuBtn {
  right: 0px;
  cursor: pointer;
  z-index: 904;
  height: 100px;
  width: 100px;
  background: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  user-select: none;
  border: none;
  &:focus {
    outline: 2px solid black !important;
  }

  @media all and (max-width: 1200px) {
    height: 80px;
    width: 80px;
    padding: 24px;
  }
  @media all and (max-width: 300px){
    height: 80px;
    width: 60px;
    padding: 15px;
  }


  img {
    width: 100%;
    height: 100%;

    &.closeBtn {
      padding: 3px;
    }
  }
}

.menuBtn.navbarbarActive {
  top: 24px;
}

.mobileSideMenuOverlay {
  width: 150vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  right: 0px;
  transform: translateX(150vw);
  z-index: 900;
  transition-duration: 0.3s;

  &.isActive {
    // background: rgba(99, 99, 99, 0.3);
    transform: translateX(45vw);
  }
}

.mobileSideMenu {
  z-index: 903;
  display: flex;
  flex-direction: column;
  background: #e5e5e5;
  position: fixed;
  left: 0px;
  transform: translateX(-100%);
  color: #656565;
  text-align: left;
  transition-duration: 0.3s;

  .mobileSideMenuInsetWrapper {
    padding: 32px;
    padding-top: 16px;
    overflow-y: auto;
    height: calc(100vh - 150px);
    transition-duration: 0.2s;

    @media all and (max-width: 800px) {
      padding: 16px;
    }

    &.isScrolled {
      height: calc(100vh - 100px);
    }
  }

  &.isActive {
    transform: translateX(0%);
  }

  .linkLarge {
    margin-top: 24px;
    font-size: 2rem;
    font-weight: 400;
    cursor: pointer;
    user-select: none;

    @media all and (max-width: 1200px) {
      font-size: 1.8rem;
      margin-top: 16px;
    }
  }

  .linkSmall {
    margin-top: 16px;
    font-size: 1.8rem;
    font-weight: 300;
    cursor: pointer;
    user-select: none;

    @media all and (max-width: 1200px) {
      font-size: 1.6rem;
    }
  }
}
