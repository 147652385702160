.youtube-embed {
    padding: 32px;
    text-align: center;
    padding-bottom: 0px;
    @media all and (max-width: 648px) {
        padding: 0px;
        padding-top: 32px;
    }

    iframe {
        width: 100%;
        max-width: 600px;
        height: 340px;
        margin: auto;
        @media all and (max-width: 648px) {
            max-width: auto;
            width: calc(100vw - 48px);
            height: calc((340 / 600) * (100vw - 48px));
        }
    }
}
.patientInfo {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;

    @media all and (max-width: 500px) {
        width: 100%;
    }

    .img__wrapper {
        margin-top: 8px;
        margin-right: 15px;

        @media all and (max-width: 300px) {
            display: none;
        }
    }

    .content__wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .title__wrapper {
            display: flex;

            .title {
                font-weight: 600;
                font-size: 3rem;
                letter-spacing: 0.05em;
                padding-top: 16px;
                padding-bottom: 16px;
                margin-right: 15px;
            }
            img {
                max-width: 30px;
            }
        }

        .links__wrapper {
            .links__wrapper_column {
                -webkit-column-count: 2;
                column-count: 2;
                column-gap: 50px;
                list-style: none;
                padding-left: 0;
                @media all and (max-width: 700px) {
                    -webkit-column-count: 1;
                    column-count: 1;
                }
            }

            .infoLink {
                display: block;
                color: #1256bc;
                font-size: 1.6rem;
                letter-spacing: 0.05em;
                line-height: 1.6;
                &:hover {
                    text-shadow: 0px 0px 1px #00000080;
                }
            }
        }
    }
}
