.prefooter {
  border-top: 2px solid #1256bc;
  padding-top: 64px;
  padding-bottom: 32px;
  margin-top: 32px;

  @media all and (max-width: 900px) {
    margin-top: 16px;
    padding-top: 32px;
    padding-bottom: 0px;
  }

  .container {
    .rowInfo {
      display: flex;
      padding-top: 32px;
      padding-bottom: 32px;
      justify-content: space-between;
      align-items: flex-start;
      gap: 30px;

      @media all and (max-width: 900px) {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      @media all and (max-width: 600px) {
        flex-direction: column;
      }
      .hoursBlock{
        max-width: 100%;
        a.topLinkWrapper{
          max-width: 100%;
          img{
            max-width: 100%;
          }
        }
      }

      .contact {
        display: flex;
        flex-direction: column;

        @media all and (max-width: 900px) {
          padding-top: 16px;
          padding-bottom: 16px;
          margin-left: -4px;
        }

        .contact-group {
          font-weight: 500;
          font-size: 2rem;
          letter-spacing: 0.05em;
          padding-top: 12px;
          padding-bottom: 12px;

          .title {
            padding-top: 0;
          }

          a {
            color: #1256bc;

            &:hover {
              color: #569AFF;
            }
          }
        }
      }

      .logos-wrapper {
        display: flex;
        flex-direction: column;
      }

      .logos {
        flex: 50%;
        text-align: right;
        margin-bottom: 10px;

        @media all and (max-width: 1030px) {
          flex-wrap: wrap;
        }

        @media all and (max-width: 900px) {
          display: none;
        }

        img {
          margin-bottom: 32px;
          height: 56px;
          margin-left: auto;
          margin: 10px;

          &.size-small {
            height: 52px;
          }

          &.size-medium {
            height: 56px;
          }

          &.size-large {
            height: 64px;
          }
        }
      }

      .title {
        font-weight: 500;
        font-size: 2rem;
        letter-spacing: 0.05em;
        padding-bottom: 12px;
        padding-top: 32px;
      }


      p {
        font-size: 1.6rem;
        letter-spacing: 0.05em;
        line-height: 1.4;
      }
    }

    .rowLinks {
      padding-top: 32px;
      padding-bottom: 32px;
      display: flex;
      flex-wrap: wrap;
      max-width: 1200px;
      margin-left: -10px;

      @media all and (max-width: 900px) {
        padding-top: 16px;
        padding-bottom: 16px;
        margin-left: -4px;
      }

      .topLinkWrapper {
        font-weight: 300;
        font-size: 1.6rem;
        margin-right: 48px;
        margin-bottom: 24px;
        cursor: pointer;
        position: relative;
        padding: 10px;

        @media all and (max-width: 900px) {
          padding: 4px;
          margin-bottom: 16px;
          margin-right: 32px;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
}