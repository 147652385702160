.link-btn {
  font-size: 2rem;
  padding: 12px 96px;
  border: 2px solid #1256BC;
  color: #1256BC;
  font-weight: 500;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  user-select: none;


  @media all and (max-width: 1600px) {
    padding: 12px 64px;
  }

  @media all and (max-width: 1200px) {
    padding: 12px 32px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.03);
    outline: 1px solid #1256BC;
  }

  &:active {
    background: rgba(0, 0, 0, 0.05);
  }

  &.link-btn--blue {
    border: 2px solid #1256BC;
    color: #1256BC;
  }

  &.link-btn--dark {
    border: 2px solid black;
    color: black;

    &:hover {
      outline: 1px solid black;
    }
  }

  &.link-btn--white {
    border: 2px solid white;
    color: white;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
      outline: 1px solid white;
    }
  }
}