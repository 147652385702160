.footer {
  .container {
    padding-top: 12px;
    padding-bottom: 24px;
    flex-direction: row;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .leftCol {
      width: 50%;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.05em;
      margin-top: auto;
      margin-bottom: auto;

      @media all and (max-width: 600px) {
        text-align: center;
        width: 100%;
        padding-bottom: 12px;
      }
    }

    .rightCol {
      width: 50%;
      white-space: nowrap;
      font-size: 1.4rem;
      letter-spacing: 0.05em;
      text-align: right;
      @media all and (max-width: 600px) {
        width: 100%;
        text-align: center;
      }
      a {
        height: 34px;
        text-decoration: underline;
      }

      img {
        height: 34px;
        @media all and (max-width: 1200px) {
          height: 28px;
        }
      }
      p{
        white-space: normal;
      }
    }
  }
}
