.home-page {
  .hero__section {
    height: 440px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    @media all and (max-width: 1200px) {
      height: 360px;
    }

    .hero__content,
    .hero__img {
      padding-top: 30px;

      align-items: center;
      width: 100%;
      height: 100%;
      display: flex;
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;

      @media all and (max-width: 1200px) {
        background-position: center;
      }

      @media all and (max-width: 700px) {
        display: none;
      }
    }

    .hero__content {
      width: 80%;
      background: #1256bc;
      padding-left: 128px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: white;

      @media all and (max-width: 1200px) {
        padding-left: 32px;
      }

      @media all and (max-width: 700px) {
        width: 100%;
      }

      @media all and (max-width: 600px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      .hero__title {
        font-size: 3.6rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        padding-right: 32px;

        @media all and (max-width: 600px) {
          padding-right: 0px;
          font-size: 2.6rem;
          padding-top: 15%;
        }
      }

      .hero__description {
        font-size: 2.4rem;
        letter-spacing: 0.05em;
        margin-bottom: 0px;
        padding-top: 8px;
        padding-right: 32px;

        @media all and (max-width: 600px) {
          padding-right: 0px;
        }
      }
    }
  }

  .shortLinks__section {
    padding-top: 64px;
    padding-bottom: 64px;
    overflow: hidden;

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 35px;

      @media all and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
      }

      @media all and (max-width: 700px) {
        display: grid;
        grid-template-columns: 1fr;
      }

      .shortInfoLink {
        padding: 20px;
        margin-bottom: 0;
        margin-top: 0;

        @media all and (max-width: 700px) {
          padding: 20px 0;
        }
      }
    }
  }

  .patientInfo__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 64px;
    padding-top: 64px;
    background-color: rgb(233, 233, 233);
  }

  .actionBar__section {
    display: flex;
    background: #1256bc;
    padding-top: 64px;
    padding-bottom: 64px;
    color: white;

    .row {
      display: flex;
      align-items: center;
      .col {
        padding-top: 32px;
        padding-bottom: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
          background: hsl(216, 83%, 35%);
          &:first-child {
          background: hsl(216, 83%, 45%);
        }
      }
      a {
        .link-btn {
          // width: fit-content;
        }
      }
    }

    .actionBarTitle {
      font-weight: 500;
      font-size: 3.6rem;
      text-align: center;
      letter-spacing: 0.05em;
      padding-bottom: 64px;

      @media all and (max-width: 1600px) {
        //padding: 32px;
        font-size: 3.2rem;
        padding-bottom: 32px;
      }

      @media all and (max-width: 700px) {
        font-size: 2.6rem;
        padding-bottom: 8px;
      }
    }
  }

  .news__section {
    padding-top: 64px;
    padding-bottom: 64px;

    .news__section__subtitle {
      font-weight: 500;
      font-size: 3rem;
      letter-spacing: 0.05em;
      color: #1256bc;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .btnRow {
      margin: auto;
      margin-top: 32px;
    }
  }

  .shortLinks__section__others {
    padding-top: 64px;
    padding-bottom: 64px;

    @media all and (max-width: 900px) {
      padding-top: 0;
    }

    .row {
      display: flex;
      // width: calc(100% + 32px);
      // margin-left: -16px;
      flex-wrap: wrap;
      justify-content: space-between;

      .shortInfoLink {
        width: 30%;
        padding: 16px;

        @media all and (max-width: 900px) {
          width: 100%;
        }
      }
    }
  }
}
