.font-size-changer {
  display: flex;
  margin-right: 9px;
  margin-left: 1px;
  position: relative;
  height: auto;

  &::after {
    display: block;
    position: absolute;
    width: 8%;
    left: 4%;
    height: 6px;
    border-radius: 5px;
    background: #777;
    content: "";
    top: 1px;
    transform: translateX(-50%);
    z-index: 3;
    transition-duration: 0.2s;
  }

  &:hover {
    &::after {
      background: black;
    }
  }

  .changer-btn {
    align-self: center;
    border: 2px solid #585858;
    width: 60px;
    height: 50px;
    margin-left: -1px;
    margin-right: -1px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
    user-select: none;
    z-index: 0;
    @media all and (max-width: 1260px) {
      width: 54px;
      height: 44px;
    }

    &:focus{
      z-index: 1;
    }

    img {
      user-select: none;
      width: 100%;
      height: auto;
      opacity: 0.75;
    }

    &:active {
      border: 2px solid #1256bc;
    }

    &:hover {
      z-index: 1;

      img {
        opacity: 1;
      }
    }
  }
}

// .font-size-6 .changer-btn--minus {
//   cursor: not-allowed;
//   img {
//     opacity: 0.25 !important;
//   }
//   &:active {
//     border: 2px solid #e5e5e5;
//   }
//   &:hover {
//     img {
//       opacity: 0.25 !important;
//     }
//   }
// }

.font-size-6 .changer-btn--minus,
.font-size-14 .changer-btn--plus {
  cursor: not-allowed;
  img {
    opacity: 0.25 !important;
  }
  &:active {
    border: 2px solid #7f7f7f;
  }
  &:hover {
    img {
      opacity: 0.25 !important;
    }
  }
}

.font-size-6 {
  .font-size-changer {
    &::after {
      left: 4%;
    }
  }
}

.font-size-7 {
  .font-size-changer {
    &::after {
      left: 16%;
    }
  }
}

.font-size-8 {
  .font-size-changer {
    &::after {
      left: 27.5%;
    }
  }
}
.font-size-9 {
  .font-size-changer {
    &::after {
      left: 39%;
    }
  }
}

.font-size-10 {
  .font-size-changer {
    &::after {
      left: 50%;
    }
  }
}

.font-size-11 {
  .font-size-changer {
    &::after {
      left: 61%;
    }
  }
}

.font-size-12 {
  .font-size-changer {
    &::after {
      left: 72.5%;
    }
  }
}

.font-size-13 {
  .font-size-changer {
    &::after {
      left: 84%;
    }
  }
}

.font-size-14 {
  .font-size-changer {
    &::after {
      left: 96%;
    }
  }
}
