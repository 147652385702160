.TextInput {
  padding: 8px 0px;
  position: relative;

  &.hidden-bridge {
    position: absolute;
    z-index: -999999;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  .validation-messages {
    transition-duration: 0.15s;
    display: inline-block;
    transform: translateY(20px);
    color: var(--color-error);
    opacity: 0;

    &.active {
      color: red !important;
      opacity: 1;
      transform: translateY(0px);
    }
  }
  label {
    display: block;
    padding-bottom: 5px;
    padding-top: 3px;
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: -0.03em;
  }
  textarea,
  input {
    position: relative;
    z-index: 1;
    width: 100%;
    border-width: 2px;
    padding: 10px 12px;
    letter-spacing: -0.03em;
    font-size: 1.6rem;
    border-color: var(--color-border-light);
    border-style: solid;
    background: var(--color-background);
    background: white;
    color: var(--color-fonts);
    &:focus {
      border-color: #1256bc;
    }
  }

  textarea {
    resize: vertical;
  }
}
