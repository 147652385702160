.news-page {
  @media all and (min-width: 1200px) {
    .news-tile {
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        margin-top: 0px;
      }
    }
  }
  @media all and (min-width: 700px) {
    .news-tile {
      &:nth-child(1), &:nth-child(2) {
        margin-top: 0px;
      }
    }
  }
  @media all and (min-width: 0px) {
    .news-tile {
      &:nth-child(1) {
        margin-top: 0px;
      }
    }
  }
}