a {
  text-decoration: none;
  color: black;
}
a.external-link {
  text-decoration: underline;
  color: #1256bc;

  &:hover {
    color: #569aff;
  }
}

.toast {
  animation-duration: 0.3s !important;
  font-size: 0.9rem !important;
  border-radius: 4px !important;
  padding: 5px 20px !important;
  min-height: 30px !important;
  color: white !important;
  font-weight: 600 !important;
}

.toasts-container {
  top: 80px !important;
  right: 25px !important;

  .toast {
    font-size: 1.5rem !important;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.overlay {
  // position: fixed;
  // height: 100%;
  // transform: scaleX(1);
  transition-duration: 0.3s;
}

.overlay.isActive {
  // transform: scaleX(0.97) translateX(40px);
  // transition-duration: 0.3s;
  opacity: 0.4;
}

// *::-webkit-scrollbar-track {
//   background-color: #ddd;
//   width: 5px;
//   border-left: 0px solid #555;
//   border-right: 0px solid #555;
// }

// *::-webkit-scrollbar {
//   background-color: #ddd;
//   width: 5px;
//   border-left: 0px solid #555;
//   border-right: 0px solid #555;
// }

// *::-webkit-scrollbar-thumb {
//   width: 5px;
//   max-height: 100px;
//   background-color: #ddd;
//   border-left: 0px solid #555;
//   border-right: 0px solid #555;
// }

// *::-webkit-scrollbar-track-piece {
//   background-color: #eee;
//   width: 5px;
//   border-left: 0px solid #555;
//   border-right: 0px solid #555;
// }

// *::-webkit-scrollbar-track {
//   background-color: #F5F5F5;
// }

// *::-webkit-scrollbar {
//   width: 6px;
//   background-color: #F5F5F5;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: #000000;
// }

.btn-material-nav {
  width: 100%;
  background: #1256bc;
  text-align: center;
  margin: auto;
  line-height: 240%;
  outline: none;
  user-select: none;
  cursor: default;
  font-size: 1.4rem;
  font-weight: 500;
  word-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;
  color: white;
  border: none;
  &:focus {
    outline: 2px solid black !important;
  }
  &:disabled {
    filter: grayscale(1) brightness(1.5);
    cursor: not-allowed;
  }
}

.btn-material-cancel {
  background: #bc1212;
  text-align: center;
  margin: auto;
  padding: 8px 12px;
  outline: none;
  user-select: none;
  cursor: default;
  font-size: 1.4rem;
  font-weight: 500;
  word-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;
  color: white;
  border: none;
  &:focus {
    outline: 2px solid black !important;
  }

  &:disabled {
    filter: grayscale(1) brightness(1.5);
    cursor: not-allowed;
  }
}
