.ModalWrapper {
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0px;
  left: 0px;
  display: none;
  align-items: center;
  justify-content: center;
  .Modal {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 90vw;
    max-height: 90vh;
    width: auto;
    background: white;
    padding: 32px 32px 24px 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    @media all and (max-width: 900px) {
      max-width: 96vw;
      max-height: 96vh;
      padding: 16px 16px 12px 16px;
    }

    .modal-header {
      display: flex;
      border-bottom: 1px solid #f7f7f7;
      padding-bottom: 12px;
      margin-bottom: 16px;
      @media all and (max-width: 900px) {
        padding-bottom: 8px;
        margin-bottom: 8px;
      }
      margin-top: -4px;
      .Modal_title {
        width: 100%;
        font-size: 1.8rem;
        font-weight: 600;
      }
      .cancel-icon {
        position: relative;
        padding: 12px;
        border-radius: 4px;
        margin-top: 0px;
        margin-right: -4px;
        cursor: pointer;

        &::after,
        &::before {
          content: "";
          position: absolute;
          background: black;
          width: 16px;
          height: 1.5px;
          border-radius: 3px;
          left: 50%;
          top: 50%;
        }

        &::before {
          transform: translateY(-50%) translateX(-50%) rotate(45deg);
        }
        &::after {
          transform: translateY(-50%) translateX(-50%) rotate(-45deg);
        }
      }
    }
    .modal-body {
      height: 100%;
    }
    .modal-footer-wrapper {
      margin-top: auto;
      .modal-footer {
        margin-top: 24px;
        display: flex;
        justify-content: right;
        border-top: 1px solid #f7f7f7;
        .button {
          width: auto;
          margin-top: 16px;
          margin-left: 16px;
        }
      }
    }
  }

  &.--isOpen {
    display: flex;
  }

  iframe {
    width: 75vw;
    max-width: calc(1200px - 64px);
    height: 100%;
    @media all and (max-width: 900px) {
      width: 90vw;
    }
  }
}
.open-modal-button {
  display: inline;
  width: fit-content !important;
}
