.download-block {
  display: flex;
  padding-right: 64px;
  padding-left: 4px;
  margin-top: 16px;
  flex-wrap: wrap;

  @media all and (max-width: 700px) {
    width: 100%;
    padding-top: 16px;
  }

  .download-block__icon {
    width: 36px;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }

    margin-right: 32px;
  }


  .download-block__content {
    display: flex;
    flex-direction: column;

    .download-block__title {
      font-weight: 300;
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      width: 100%;
      color: black;
    }

    .download-block__downloadBtn {
      font-size: 1.4rem;
      width: 100%;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-top: 4px;
      cursor: pointer;
      text-align: center;
      letter-spacing: 0.05em;
      background: #E5E5E5;
      height: auto;
      display: block;
      text-decoration: none;
      color: black;
      font-weight: 400;
      transition-duration: 0.2s;

      &:hover {
        background: #1256BC;
        color: white;
        transition-duration: 0s;
      }

      &:active {
        background: #569AFF;
        transition-duration: 0s;
      }
    }
  }
}