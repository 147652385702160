.pjm-logo {
  align-self: center;
  margin-right: 128px;
  border: 2px solid #585858;
  width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  @media all and (max-width: 1200px) {
    margin-right: 32px;
  }
  @media all and (max-width: 1260px) {
    width: 54px;
    height: 44px;
  }

  &:active {
    border: 2px solid #1256bc;
  }
  a {
    padding: 4px;
    width: 100%;
    img {
      transform: translateY(2px);
      padding: 4px;
      width: 100%;
      height: auto;
      opacity: 0.7;
      min-width: 42px;
    }
  }
  &:hover {
    img {
      opacity: 1;
    }
  }
}