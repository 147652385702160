.skipLink{
    pointer-events: none;
    background-color: #494949;
    color: #fff;
    padding: 15px;
    border: none;
    font-weight: 600;
    letter-spacing: .5px;
    position: absolute;
    text-decoration: none;
    top: 10px;
    left: 30%;
    opacity: 0;
    font-size: 1.6rem;
    z-index: 9989 ;

    &:focus{
        opacity: 1;
    }
}