.search-bar {
  height: 100%;
  // background: red;
  padding-right: 8px;
  display: flex;
  position: relative;
  @media all and (max-width: 1200px) {
    padding-right: 32px;
  }

  @media all and (max-width: 600px) {
    display: none;
  }

  .search-label {
    position: absolute;
    font-size: 1.2rem;
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    border: 2px solid #585858;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: -2px;
    opacity: 1;

    &.isActive {
      border: 3px solid #1256bc;
      opacity: 1;
    }

    input {
      height: 46px;
      width: 420px;
      border: 0px solid #1256bc;
      padding-right: 12px;
      padding-left: 12px;
      font-size: 1.5rem;

      // &::placeholder {
      //   font-size: 1.6rem;
      //   color:  #000000;
      //   opacity: 1;
      // }

      @media all and (max-width: 1260px) {
        width: 300px;
        height: 40px;
      }

      @media all and (max-width: 800px) {
        width: 280px;
        height: 40px;
      }

      @media all and (max-width: 700px) {
        width: 180px;
        height: 40px;
      }
    }

    .inputIcon {
      padding-right: 12px;
      padding-left: 12px;
      cursor: pointer;
      transition-duration: 0.2s;
      filter: contrast(1.5);
      border: none;
      background-color: transparent;
      &:hover {
        transform: scale(1.25);
      }
      &:focus{
        border: 2px solid #1256bc;
      }

      img {
        width: 26px;
        padding-top: 2px;
      }

      @media all and (max-width: 1200px) {
        img {
          width: 20px;
        }
      }
    }
  }
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

