.news-tile {
  margin-top: 48px;
  margin-bottom: 48px;

  @media all and (max-width: 1600px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  @media all and (max-width: 1200px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  display: flex;
  flex-direction: column;

  .topLinkWrapper {
    display: flex;
    flex-direction: column;

    &:hover {
      cursor: pointer;

      .title {
        text-decoration-line: underline;
        text-underline-offset: 3px;
        color: #569AFF;
      }
    }
  }

  .news-tile__img {
    width: 100%;
    height: auto;
  }


  .news-tile__title {
    color: #1256BC;
    font-weight: 500;
    font-size: 2.0rem;
    letter-spacing: 0.05em;
    transition-duration: 0.2s;
    padding-top: 16px;
  }

  .news-tile__description {
    padding-top: 16px;
    font-size: 1.6rem;
    color: black;
    font-weight: 300;
    letter-spacing: 0.05em;
    line-height: 1.6;

    a{
      text-decoration: underline;
      color: #1256BC;

      &:hover{
        color: #569AFF;
      }
    }
  }


}

.news-tiles-wrapper {
  display: flex;
  width: calc(100% + 96px);
  margin-left: -48px;
  flex-wrap: wrap;

  @media all and (max-width: 1600px) {
    width: calc(100% + 64px);
    margin-left: -32px;
  }

  @media all and (max-width: 1200px) {
    width: calc(100% + 32px);
    margin-left: -16px;
  }

  .news-tile {
    width: 33.3333%;
    padding-left: 48px;
    padding-right: 48px;

    @media all and (max-width: 1600px) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @media all and (max-width: 1200px) {
      width: 50%;
      padding-left: 16px;
      padding-right: 16px;
    }

    @media all and (max-width: 700px) {
      width: 100%;
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;

    @media all and (max-width: 1600px) {
      margin-top: 32px;
    }

    .pageBtn {
      font-weight: 500;
      font-size: 2rem;
      text-align: center;
      letter-spacing: 0.05em;
      color: #1256BC;
      padding: 4px 12px;
      cursor: pointer;
      transition-duration: 0.2s;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }

      &.isActive {
        border: 2px solid #1256BC;
        transform: translateY(-5px);
      }
    }
  }
}