.main-header {
  padding: 32px 0px;
  width: 100%;
  font-weight: 500;
  font-size: 3rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media all and (max-width: 1600px) {
    font-size: 2.8rem;
  }

  @media all and (max-width: 1200px) {
    font-size: 2.6rem;
  }

  @media all and (max-width: 700px) {
    // font-size: 2.4rem;
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}