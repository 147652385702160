.standard-text-block {
  font-size: 1.6rem;

  img {
    width: 100%;
    height: auto;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  p {
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.6;
    letter-spacing: 0.05em;
    margin-bottom: 0px;
    color: black;
  }

  .title {
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.6;
    letter-spacing: 0.05em;
    color: #1256BC;
  }

  .subtitle {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.6;
    letter-spacing: 0.05em;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  .red {
    font-weight: 600;
    color: black;
  }

  .topLinkWrapper {
    display: inline;
    color: #1256BC;
    text-decoration: underline;
    text-underline-offset: 3px;
    letter-spacing: 0.05em;
    cursor: pointer;

    &:hover {
      color: #569AFF;
    }

    &:active {
      color: #569AFF;
    }
  }

  a {
    color: #1256BC;
    text-decoration: underline;
    text-underline-offset: 3px;
    letter-spacing: 0.05em;

    &:hover {
      color: #569AFF;
    }

    &:active {
      color: #569AFF;
    }
  }

  ul {
    font-weight: 300;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    list-style-type: '\2022   '; //none;

    li {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  strong {
    font-weight: 500;
  }

  &.justify {
    text-align: justify;

    p {
      text-align: justify;
    }
  }
}