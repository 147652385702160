.rules-page {
  .searchBlock {
    padding-top: 8px;
    padding-bottom: 8px;

    &:nth-child(2) {
      padding-top: 0px;
    }

    p {
      padding: 16px;
      background: #EEE;
      font-size: 2rem;
      font-weight: 400;
      cursor: pointer;
      user-select: none;

      @media all and (max-width: 1200px) {
        font-size: 1.8rem;
      }

      &:hover {
        background: #1256BC;
        color: white;
      }
    }

    &.no-data {
      p {
        user-select: initial;
        cursor: default;
        &:hover {
          background: #EEE;

          color: black;
        }

      }
    }
  }
}